import {
  Box,
  Button,
  FormControl,
  Grid,
  /* InputLabel, */
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import "./index.scss";
import ModalForm from "./ModalForm";
import ActivePosition from "./ActivePositionTable";
// import LineChart from "./LineChart";
import PortoflioModal from "./PortoflioModal";
import RealisedModal from "./RealisedModal";
import apiClient from "../../utils";
import { apiFailure } from "../../logic";
import { useDispatch } from "react-redux";
import SquaredOffPositionTable from "./SquaredOffPositionTable";

const Portfolio = () => {
  let API_CONFIG = {
    fetchPortfolio: {
      method: "GET",
      url: "/fetchAllPortfolio",
      data: {},
    },
    fetchPosition: {
      method: "GET",
      url: "/fetchActivePosition",
      data: {},
    },
    fetchExitPosition: {
      method: "GET",
      url: "/fetchAllExitPortfolio",
      data: {},
    },
  };
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [portfolioOptions, setPortfolioOptions] = useState([]);
  const [portfolioValue, setPortfolioValue] = useState();
  const [, /* availableFundValue */ setAvailableFundValue] = useState();
  const [tableData, setTableData] = React.useState();
  const [exitTableData, setExitTableData] = React.useState();

  const [realisedOpen, setRealisedOpen] = useState(false);
  const [portfolioOpen, setPortfolioOpen] = useState(false);

  const handleForm = () => {
    setModalOpen(true);
  };

  const handleOpen = () => {
    setPortfolioOpen(true);
  };

  const fetchActivePortfolios = () => {
    const apiPayload = { ...API_CONFIG.fetchPortfolio };

    apiClient(apiPayload)
      .then((res) => {
        setPortfolioOptions(res?.data);

        setPortfolioValue(res?.data[0]?.portfolioName);
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };
  const fetchSquaredOffPortfolios = () => {
    const apiPayload = { ...API_CONFIG.fetchExitPosition };
    apiPayload.url += `?name=${portfolioValue}`;

    apiClient(apiPayload)
      .then((res) => {
        console.table(res?.data);
        setExitTableData(res?.data);
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };

  const fetchActivePosition = () => {
    const apiPayload = { ...API_CONFIG.fetchPosition };
    apiPayload.url += `?name=${portfolioValue}`;
    apiClient(apiPayload)
      .then((res) => {
        console.log("response", res);
        setTableData(res?.data);
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };

  useEffect(() => {
    fetchActivePortfolios();
  }, []);

  useEffect(() => {
    if (portfolioValue) {
      console.log("useeffect", portfolioOptions[0]?.availableFundBalance);
      setAvailableFundValue(portfolioOptions[0]?.availableFundBalance);

      fetchActivePosition();
      fetchSquaredOffPortfolios();
    }
  }, [portfolioValue]);

  let sumCurrentValue = 0;
  let sumTotalAmountInvested = 0;
  let sumEntryQuantity = 0;

  if (tableData) {
    console.log("ifcondition", tableData);
    for (const entry of tableData) {
      if (entry?.entryPrice !== null && entry?.entryPrice !== undefined) {
        sumEntryQuantity += entry.entryPrice * entry?.quantity;
        console.log("entry1", sumEntryQuantity);
      }
      console.log(entry?.currentValue, entry);
      if (entry?.currentValue !== undefined) {
        sumCurrentValue += entry.currentValue;
        console.log("entry2", sumCurrentValue);
      }

      if (entry?.totalAmountInvested !== undefined) {
        sumTotalAmountInvested += entry.totalAmountInvested;
        console.log("entry3", sumTotalAmountInvested);
      }
    }
  }

  const renderOptions = () => {
    return portfolioOptions?.map((option, index) => (
      <MenuItem
        key={index}
        value={option?.portfolioName}
        onClick={() => setPortfolioValue(option?.portfolioName)}
      >
        {option?.portfolioName}
      </MenuItem>
    ));
  };

  return (
    <div className="portfolio">
      <RealisedModal
        realisedOpen={realisedOpen}
        setRealisedOpen={setRealisedOpen}
      />

      <ModalForm
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        portfolioValue={portfolioValue}
      />
      <PortoflioModal
        portfolioOpen={portfolioOpen}
        setPortfolioOpen={setPortfolioOpen}
      />
      <Grid container className="dropdown-grid">
        <Grid item>
          <Paper
            sx={{ width: 220, backgroundColor: "#002e64", color: "#ffffff" }}
          >
            <Box sx={{ width: 220 }}>
              <FormControl fullWidth className="formControl">
                {/* <InputLabel id="demo-simple-select-label" >
                  All time Change
                </InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={portfolioValue || ""}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                    color: "#ffffff",
                  }}
                  // onChange={handleChange}
                  className="select-box"
                >
                  {portfolioOptions && renderOptions()}
                  {/* <MenuItem value={"portfolio1"}>Portfolio 1</MenuItem>
                  <MenuItem value={"porfolio2"}>Portfolio 2</MenuItem> */}
                  <MenuItem
                    value={"add"}
                    onClick={handleOpen}
                    style={{
                      color: "#002e64",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Add Portfolio &nbsp; &nbsp;
                    <AddIcon />
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Paper>
        </Grid>
        {/* <Grid item>
          <Paper sx={{ width: 220 }}>
            <Box sx={{ width: 220 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  All time Change
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={age}
                  label="Age"
                  // onChange={handleChange}
                >
                  <MenuItem value={10}>All Time Change</MenuItem>
                  <MenuItem value={20}>Monthly Change</MenuItem>
                  <MenuItem value={30}>Weekly Change</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Paper>
        </Grid> */}
        {/* <Grid item> */}
        <Button className="add-button" variant="contained" onClick={handleForm}>
          {" "}
          Add Positions &nbsp;
          <AddIcon />
        </Button>
        {/* </Grid> */}
      </Grid>
      {/* <div className="second-row">
        <Box className="paper-boxes">
          <Paper>
            <Typography className="text-paper-box">
              Available Fund Balance
            </Typography>
            {availableFundValue && (
              <Typography className="text-paper-box-row2">
                $ {availableFundValue - sumEntryQuantity}
              </Typography>
            )}
          </Paper>
        </Box>
        <Box className="paper-boxes">
          <Paper>
            <Typography className="text-paper-box">
              Total Amount Invested
            </Typography>
            <Typography className="text-paper-box-row2">
              {" "}
              $ {sumTotalAmountInvested}
            </Typography>
          </Paper>
        </Box>{" "}
        <Box className="paper-boxes">
          <Paper>
            <Typography className="text-paper-box">Current Value</Typography>
            <Typography className="text-paper-box-row2">
              $ {sumCurrentValue}
            </Typography>
          </Paper>
        </Box>{" "}
        <Box className="paper-boxes">
          <Paper>
            <Typography className="text-paper-box">Unrealised P/L</Typography>
            <Typography className="text-paper-box-row2">
              $ {(sumCurrentValue - sumTotalAmountInvested).toFixed(2)}
              <br></br>
            </Typography>
          </Paper>
        </Box>{" "}
        <Box
          className="paper-boxes-realised"
          onClick={() => setRealisedOpen(true)}
        >
          <Paper>
            <Typography className="text-paper-box">Realised Value</Typography>
            <Typography className="text-paper-box-row2">$ 50 (+5%)</Typography>
          </Paper>
        </Box>
      </div> */}
      {/* <Paper style={{ margin: "0% 2%" }}>
        <Typography className="linechart-heading">
          Portfolio Vs Index Performance
        </Typography>
        <LineChart></LineChart>
      </Paper> */}
      <Paper style={{ margin: "2% 2%" }}>
        <Typography className="table-heading">Active Position</Typography>
        <div className="position-table">
          <ActivePosition
            tableData={tableData}
            portfolioValue={portfolioValue}
          />
        </div>
      </Paper>
      <Paper style={{ margin: "2% 2%" }}>
        <Typography className="table-heading">Squared-Off Position</Typography>
        <div className="position-table">
          <SquaredOffPositionTable
            exitTableData={exitTableData}
            // portfolioValue={portfolioValue}
          />
        </div>
      </Paper>
      {/* <AddPosition modalOpen={modalOpen} setModalOpen={setModalOpen} /> */}
    </div>
  );
};

export default Portfolio;
