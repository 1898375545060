import React from "react";

export const CatalystData = ({ cataLystData }) => {
  return (
    <>
      {cataLystData && (
        <div className="catalyst_data">
          <div className="catalyst_dataLeft">
            <p
              style={{ color: "#076281", fontSize: "16px", fontWeight: "bold" }}
            >
              {cataLystData?.ticker}
            </p>
            <p
              style={{ color: "#05c00d", fontSize: "14px", fontWeight: "bold" }}
            >
              ${cataLystData.cmp}
            </p>
            <p
              style={{ color: "#1f1f1f", fontSize: "14px", fontWeight: "bold" }}
            >
              {cataLystData.label}
            </p>
          </div>
          <div className="catalyst_dataRight">
            <p
              style={{ color: "#454545", fontSize: "14px", marginLeft: "30px" }}
            >
              {cataLystData.note}
            </p>
            <p
              style={{ color: "#757575", fontSize: "14px", marginLeft: "30px" }}
            >
              Mcap (in Mln): {cataLystData.mcap}
            </p>
            <p
              style={{ color: "#1f1f1f", fontSize: "14px", marginLeft: "30px" }}
            >
              Expected Readout Date: {cataLystData.catalyst_date}
              <br />
              Our Analysis Date: {cataLystData.Date}
              <br />
            </p>
          </div>
        </div>
      )}
    </>
  );
};
