import { Box, FormControl, Modal, OutlinedInput, Button } from "@mui/material";
import React, { useState } from "react";
import "./index.scss";
import apiClient from "../../utils";
import { useDispatch } from "react-redux";
import { apiFailure } from "../../logic";

const PortoflioModal = ({ portfolioOpen, setPortfolioOpen }) => {
  const API_CONFIG = {
    addPortfolio: {
      method: "POST",
      url: "addPortfolio/",
      data: {},
    },
  };
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    portfolioName: "",
    availableFundBalance: 0,
  });

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = () => {
    const apiPayload = { ...API_CONFIG.addPortfolio };
    apiPayload.data = formData;
    apiClient(apiPayload)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,

    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    // pt: 2,
    px: 4,
    // pb: 3,
    py: 4,
  };

  return (
    <div className="portfolio-add">
      <Modal
        open={portfolioOpen}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormControl className="form-add-portfolio">
            <label htmlFor="name" className="labels">
              Portfolio Name:
            </label>
            <OutlinedInput
              id="name"
              name="name"
              value={formData.portfolioName}
              onChange={(e) =>
                handleInputChange("portfolioName", e.target.value)
              }
              //   placeholder="Enter portfolio name"
              style={{ backgroundColor: "#ededed" }}
            />
          </FormControl>
          {/* <FormControl className="form-add-portfolio">
            <label htmlFor="available_fund_balance" className="labels">
              Available Fund Balance:
            </label>
            <OutlinedInput
              id="available_fund_balance"
              name="available_fund_balance"
              value={
                formData.availableFundBalance === 0
                  ? ""
                  : formData.availableFundBalance
              }
              onChange={(e) =>
                handleInputChange(
                  "availableFundBalance",
                  parseFloat(e.target.value)
                )
              }
              // onChange={(e, newValue) => {
              //   handleInputChange("availableFundBalance", newValue);
              //   console.log("/////", newValue);
              // }}
              //   type="number"
              //   placeholder="Enter available fund balance"
              style={{ backgroundColor: "#ededed" }}
            />
          </FormControl> */}
          <div className="btn-row">
            <Button
              onClick={() => setPortfolioOpen(false)}
              variant="contained"
              style={{
                width: "70%",
                backgroundColor: "#757575",
                color: "#fffff",
              }}
            >
              Cancel
            </Button>{" "}
            <Button
              type="submit"
              variant="contained"
              style={{
                width: "70%",
                backgroundColor: "#002e64",
                color: "#ffffff",
              }}
              onClick={handleSubmit}
            >
              Add Portfolio
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PortoflioModal;
