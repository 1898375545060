import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import RealisedTable from "./RealisedTable";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BasicModal = ({ realisedOpen, setRealisedOpen }) => {
  const closeModal = () => {
    setRealisedOpen(false);
  };
  return (
    <div>
      <Modal
        open={realisedOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <IconButton
              style={{ display: "flex", justifyContent: "end" }}
              onClick={closeModal}
            >
              <CloseIcon
                style={{
                  cursor: "pointer",
                }}
              />
            </IconButton>

            <RealisedTable />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default BasicModal;
