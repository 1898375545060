import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
//import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ProgressCircleWrapper } from "./ProgressArc";
import Reward from "./Reward";
import { CatalystData } from "./Catalyst";
import { Button } from "@material-ui/core";
import apiClient from "../../../utils";
import { apiFailure } from "../../../logic";
import { useDispatch } from "react-redux";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import "./index.css";
import { useState } from "react";
// import { Tooltip } from "react-tooltip";
// import 'react-tooltip/dist/react-tooltip.css'

// import Xarrow from "react-xarrows";
// import { Xwrapper } from "react-xarrows";

let API_CONFIG = {
  analyzedResults: {
    method: "GET",
    url: "/analysed_catalysts/docDownload",
    responseType: "blob",
  },
  updateStatus: {
    method: "POST",
    url: "update_status/",
    data: {},
  },
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#076281",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    position: "relative",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AnalysedTable({ isAdmin, columnList, data }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleDownload = (index) => {
    return null;
  };

  const handlePDFDownload = (hashID) => {
    const apiPayload = { ...API_CONFIG.analyzedResults };

    apiPayload.url += `?docId=${hashID}`;
    // setLoading(true);

    apiClient(apiPayload)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/pdf",
          })
        );

        const a = document.createElement("a");
        a.href = url;
        a.download = "Document.pdf";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };

  const renderHeadCols = () => {
    return columnList.map((row, index) => {
      return (
        <StyledTableCell
          className="heading-col"
          key={`head_${row.lable}_${index}`}
          // align="center"
        >
          {row.label}
          {row.label === "Status" && (
            <Tooltip
              title={
                <>
                  <Typography color="inherit">
                    Stage 1 : Ticker Suggested
                  </Typography>
                  <Typography color="inherit">
                    Stage 2 : Approved - FiveT
                  </Typography>
                  <Typography color="inherit">
                    Stage 3 : Approved - PB
                  </Typography>
                  <Typography color="inherit">
                    Stage 4 : In Portfolio
                  </Typography>
                </>
              }
            >
              {" "}
              <InfoIcon />
            </Tooltip>
          )}
        </StyledTableCell>
      );
    });
  };

  const handleClick = (keyData, index, symbol, drug, indication, nct_id) => {
    console.log(keyData, index, symbol, drug);
    const url = `https://www.drugs.com/search.php?searchterm=${drug}`;
    const wikiUrl = `https://en.wikipedia.org/wiki/${indication}`;
    const nctUrl = `https://clinicaltrials.gov/study/${nct_id}`;
    if (index === 0) {
      history.push(`/company_info/?symbol=${symbol}`);
    }
    if (index === 1) {
      // history.push(`/company_info/?symbol=${symbol}`);
      window.open(url, "_blank");
    }
    if (index === 2) {
      // history.push(`/company_info/?symbol=${symbol}`);
      window.open(wikiUrl, "_blank");
    }
    if (index === 3) {
      history.push(`/riskreward/${keyData}`);
    }
    if (index === 4) {
      history.push(`/checklists/${keyData}`);
    }
    if (index === 5) {
      history.push(`/pos/${keyData}`);
    }
    if (index === 6) {
      history.push(`/moa/${keyData}`);
    }
    if (index === 7) {
      window.open(nctUrl, "_blank");
    }
    // if (index === 5) {
    //   history.push(`/priorresults/${keyData}`);
    // }
  };
  // const handleCheckboxChange = (option) => {
  //   console.log("option", option);
  //   setStatus((prevStatus) => ({
  //     ...prevStatus,
  //     [option]: !prevStatus[option],
  //   }));
  // };
  // const handleStatusUpdate = (id) => {
  //   const apiPayload = { ...API_CONFIG.updateStatus };
  //   apiPayload.data = { hash_id: id };

  //   apiClient(apiPayload)
  //     .then((res) => {
  //       console.log("res", res);
  //     })
  //     .catch((err) => {
  //       console.log({ message: err });
  //       dispatch(apiFailure(err));
  //     });
  // };
  const handleStatusUpdateWithComment = (id, comment) => {
    const apiPayload = { ...API_CONFIG.updateStatus };
    apiPayload.data = { hash_id: id, comment };

    apiClient(apiPayload)
      .then((res) => {
        console.log("res", res);
        // Add any additional logic you need after a successful update
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };
  const handleStatusUpdateWithCommentStatus = (id, comment) => {
    const apiPayload = { ...API_CONFIG.updateStatus };
    apiPayload.data = { hash_id: id, comment, status: "rejected" };
    console.log("this is called");
    apiClient(apiPayload)
      .then((res) => {
        console.log("res", res);
        // Add any additional logic you need after a successful update
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };
  const [open, setOpen] = useState(false);
  const [approveComment, setApproveComment] = useState(null);
  const [nextOrReject, setNextOrReject] = useState("");

  const setComment = (e) => {
    setApproveComment(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (op) => {
    setNextOrReject(op);
    setOpen(true);
  };

  const handleButtonClick = (id) => {
    setOpen(false);

    if (approveComment !== null) {
      // User clicked OK in the prompt
      nextOrReject === "next" &&
        handleStatusUpdateWithComment(id, approveComment);
      nextOrReject === "reject" &&
        handleStatusUpdateWithCommentStatus(id, approveComment);
    }
    // If user clicked Cancel, you can handle it as needed
  };

  const renderResults = (hashid, identity, cellData, index) => {
    if (cellData !== undefined) {
      if (identity === "pos") {
        return <ProgressCircleWrapper recPercent={cellData} />;
      }
      if (identity === "checklist_score_avg") {
        return <ProgressCircleWrapper recPercent={cellData} />;
      }
      if (identity === "moa") {
        return cellData;
      }
      if (identity === "drug_name") {
        return cellData;
      }
      if (identity === "indication") {
        return cellData;
      }
      if (identity === "nct_id") {
        return cellData;
      }
      if (identity === "prior_result") {
        return cellData;
      }
      if (identity === "risk_rewards") {
        return <Reward reWard={cellData} />;
      }
      if (identity === "catalyst_infos") {
        return <CatalystData cataLystData={cellData} />;
      }
      if (identity === "download") {
        return <Button onClick={() => handleDownload(index)}></Button>;
      }

      if (identity === "status") {
        return (
          <div
            style={{
              display: "flex",
              height: "120px",
              flexDirection: "column",
              justifyContent: "space-evenly",
              gap: "6%",
              textAlign: "center",
            }}
          >
            {console.log("celldata", cellData)}
            {cellData?.current_stage === "ticker_suggested" ? (
              <b>Ticker Suggested</b>
            ) : cellData?.current_stage === "approved_by_ft" ? (
              <b>Approved - FiveT</b>
            ) : cellData?.current_stage === "approved_by_pb" ? (
              <b>Approved - PB</b>
            ) : cellData?.current_stage === "added_to_portfolio" ? (
              <b>In Portfolio</b>
            ) : cellData?.current_stage === "rejected" ? (
              <b>Rejected</b>
            ) : (
              <br />
            )}

            <div className="timeline-container">
              <Tooltip
                title={
                  <Typography color="inherit">
                    Ticker Suggested Date: {cellData?.ticker_suggested_date}
                    <br />
                    {cellData?._comments
                      ? "Comment : " + cellData?._comments[0]?.comment?.comment
                      : ""}
                  </Typography>
                }
              >
                <img
                  className="number-icon"
                  style={
                    cellData?.ticker_suggested_date
                      ? {
                          filter:
                            "invert(44%) sepia(87%) saturate(1155%) hue-rotate(340deg) brightness(110%) contrast(101%)",
                        }
                      : cellData?.current_stage === "rejected" &&
                        cellData?.ticker_suggested_date != ""
                      ? {
                          filter:
                            "invert(16%) sepia(91%) saturate(7494%) hue-rotate(333deg) brightness(95%) contrast(103%)",
                        }
                      : {
                          filter:
                            "invert(50%) sepia(26%) saturate(3878%) hue-rotate(190deg) brightness(106%) contrast(101%)",
                        }
                  }
                  id="1"
                  // data-tooltip-content={data?.status?.ticker_suggested_date}
                  src="./circle-1.svg"
                  alt=""
                />
              </Tooltip>
              {"-->"}
              <Tooltip
                title={
                  <Typography color="inherit">
                    FiveT Approved Date: {cellData?.fiveT_approved_date}
                    <br />
                    {cellData?.ticker_suggested_comments
                      ? "Comment : " +
                        cellData?.ticker_suggested_comments[0]?.comment?.comment
                      : ""}
                  </Typography>
                }
              >
                <img
                  className="number-icon"
                  style={
                    cellData?.fiveT_approved_date
                      ? {
                          filter:
                            "invert(44%) sepia(87%) saturate(1155%) hue-rotate(340deg) brightness(110%) contrast(101%)",
                        }
                      : cellData?.current_stage === "rejected" &&
                        cellData?.fiveT_approved_date != ""
                      ? {
                          filter:
                            "invert(16%) sepia(91%) saturate(7494%) hue-rotate(333deg) brightness(95%) contrast(103%)",
                        }
                      : {
                          filter:
                            "invert(50%) sepia(26%) saturate(3878%) hue-rotate(190deg) brightness(106%) contrast(101%)",
                        }
                  }
                  id="2"
                  src="./circle-2.svg"
                  alt=""
                />
              </Tooltip>

              {"-->"}
              <Tooltip
                title={
                  <Typography color="inherit">
                    Pb Approved Date: {cellData?.pb_approved_date}
                    <br />
                    {cellData?.approved_by_ft_comments
                      ? "Comment : " +
                        cellData?.approved_by_ft_comments[0]?.comment?.comment
                      : ""}
                  </Typography>
                }
              >
                <img
                  className="number-icon"
                  style={
                    cellData?.pb_approved_date != ""
                      ? {
                          filter:
                            "invert(44%) sepia(87%) saturate(1155%) hue-rotate(340deg) brightness(110%) contrast(101%)",
                        }
                      : cellData?.current_stage === "rejected" &&
                        cellData?.pb_approved_date
                      ? {
                          filter:
                            "invert(16%) sepia(91%) saturate(7494%) hue-rotate(333deg) brightness(95%) contrast(103%)",
                        }
                      : {
                          filter:
                            "invert(50%) sepia(26%) saturate(3878%) hue-rotate(190deg) brightness(106%) contrast(101%)",
                        }
                  }
                  id="3"
                  src="./circle-3.svg"
                  alt=""
                />
              </Tooltip>

              {"-->"}
              <Tooltip
                title={
                  <Typography color="inherit">
                    Added to Portfolio Date: {cellData?.added_to_portfolio_date}
                    <br />
                    {cellData?.approved_by_pb_comments
                      ? "Comment : " +
                        cellData?.approved_by_pb_comments[0]?.comment?.comment
                      : ""}
                  </Typography>
                }
              >
                <img
                  className="number-icon"
                  style={
                    cellData?.added_to_portfolio_date
                      ? {
                          filter:
                            "invert(44%) sepia(87%) saturate(1155%) hue-rotate(340deg) brightness(110%) contrast(101%)",
                        }
                      : cellData?.current_stage === "rejected" &&
                        cellData?.added_to_portfolio_date != ""
                      ? {
                          filter:
                            "invert(16%) sepia(91%) saturate(7494%) hue-rotate(333deg) brightness(95%) contrast(103%)",
                        }
                      : {
                          filter:
                            "invert(50%) sepia(26%) saturate(3878%) hue-rotate(190deg) brightness(106%) contrast(101%)",
                        }
                  }
                  id="4"
                  src="./circle-4.svg"
                  alt=""
                />
              </Tooltip>
            </div>

            {isAdmin?.status === "YES" && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    width: "95%",
                    margin: "1%",
                    fontSize: "12px",
                    height: "auto",
                  }}
                  onClick={() => handleClickOpen("next")}
                >
                  {/* {console.log("button", isAdmin?.status)} */}
                  Move to Next Stage
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    width: "95%",
                    margin: "1%",
                    fontSize: "12px",
                    height: "auto",
                  }}
                  onClick={() => handleClickOpen("reject")}
                >
                  {" "}
                  Reject
                </Button>{" "}
              </>
            )}
            <Dialog
              open={open}
              onClose={handleClose}
              // BackdropProps={{ style: { backgroundColor: "transparent" } }}
              slotProps={{
                backdrop: {
                  style: { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                },
              }}
              PaperProps={{
                style: {
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle>
                {nextOrReject === "next" ? "Move to Next Stage" : "Rejected"}
              </DialogTitle>
              <DialogContent>
                {/* <DialogContentText>Enter your comment:</DialogContentText> */}
                <TextField
                  autoFocus
                  margin="dense"
                  id="comment"
                  label="Enter your comment"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setComment(e)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => handleButtonClick(hashid)}>
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      }
    }
  };

  // const renderCells = (row) => {
  //   return columnList.map((col, index) => (
  //     <StyledTableCell
  //       key={`row_${col.key}_${index}`}
  //       className="cells"
  //       onClick={() =>
  //         handleClick(row.hashId, index, row.catalyst_infos.ticker)
  //       }
  //     >
  //       {console.log("col", col)}
  //       {row?.[col.key] !== null
  //         ? renderResults(col.key, row[col.key], index)
  //         : "-"}
  //     </StyledTableCell>
  //   ));
  // };

  const renderCells = (row) => {
    return columnList.map((col, index) => (
      <StyledTableCell
        key={`row_${col.key}_${index}`}
        className="cells"
        onClick={() =>
          col.key !== "Analysis" &&
          handleClick(
            row.hashId,
            index,
            row?.catalyst_infos?.ticker,
            row?.drug_name,
            row?.indication,
            row?.nct_id
          )
        }
      >
        {col.key === "Analysis" ? (
          // <Button
          //   className="analysis-button"
          //   style={{
          //     backgroundColor: "#002e64",
          //     color: "#ffffff",
          //     width: "1%",
          //   }}
          //   onClick={() => handlePDFDownload(row.hashId)}
          // >
          //   <FileDownloadIcon />
          // </Button>
          <Tooltip title="Download">
            <IconButton onClick={() => handlePDFDownload(row.hashId)}>
              <FileDownloadIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            {row?.[col.key] /* !== null */
              ? renderResults(row.hashId, col.key, row[col.key], index)
              : "-"}
          </>
        )}
      </StyledTableCell>
    ));
  };

  const renderTableRows = () => {
    return data.map((row, index) => (
      <StyledTableRow key={`row_${index}`}>{renderCells(row)}</StyledTableRow>
    ));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <p style={{ paddingRight: " 2%" }}>
          {" "}
          {/*  Showing {data.length} of {count?.count} results */}
          Showing {data.length} results
        </p>
      </div>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "70vh", //
        }}
        className="ana_Table"
      >
        <Table
          sx={{ minWidth: 600 }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <TableRow>{renderHeadCols()}</TableRow>
          </TableHead>

          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
