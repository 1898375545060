import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#002e64",
    color: "#ffffff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const tableData = [];
const COL_LIST = [
  { key: "Ticker", value: "ticker" },
  { key: "Realised P/L", value: "realisedpnl" },
  { key: " Charges", value: "charges" },
  { key: "Net Realised P/L", value: "netrealisedpnl" },
  { key: "Date", value: "date" },
];
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
    color: "#002e64",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
    backgroundColor: "#002e64",
    color: "#ffffff",
  },
}));
const renderHeadCol = () => {
  return COL_LIST.map((row, index) => {
    return (
      <StyledTableCell key={`head_${row.label}_${index}`} align="center">
        {row?.key}
      </StyledTableCell>
    );
  });
};

const renderRows = () => {
  if (!tableData || tableData.length === 0) {
    // Render a message when there's no data
    return (
      <StyledTableRow>
        <StyledTableCell colSpan={COL_LIST.length} align="center">
          No data available
        </StyledTableCell>
      </StyledTableRow>
    );
  }

  return tableData.map((data, dataIndex) => (
    <StyledTableRow key={`row_${dataIndex}`}>
      {COL_LIST.map((col, colIndex) => (
        <StyledTableCell key={`cell_${dataIndex}_${colIndex}`} align="center">
          typeof data[col.value] === "number" ? ( data[col.value].toFixed(2) ) :
          ( data[col.value] )
        </StyledTableCell>
      ))}
    </StyledTableRow>
  ));
};
export default function CustomizedTables() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>{renderHeadCol()}</TableRow>
        </TableHead>
        <TableBody>{renderRows()}</TableBody>
      </Table>
    </TableContainer>
  );
}
