import {
  Box,
  Button,
  IconButton,
  Input,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./ExitModal.scss";
import ClearIcon from "@mui/icons-material/Clear";
import apiClient from "../../utils";
import { apiFailure } from "../../logic";
import { useDispatch } from "react-redux";

const ExitTradeModal = ({
  exitTradeOpen,
  setExitTradeOpen,
  rowdata,
  portfolioValue,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
  };
  const dispatch = useDispatch();

  let API_CONFIG = {
    exit_position: {
      method: "POST",
      url: "/portfolio_exit/",
      data: {},
    },
  };

  const [formData, setFormData] = useState({
    exitDate: new Date(),
    quantity: "",
    exitPrice: "",
    // charges: "",
    portfolioValue: "",
    entryPrice: "",
    entryQuantity: "",
    entryDate: "",
  });

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
      ticker: rowdata?.ticker,
      tradeType: rowdata?.buySell,
      companyName: rowdata?.companyName,
      entryPrice: rowdata?.entryPrice,
      entryDate: rowdata?.date,
      entryQuantity: rowdata?.quantity,
      portfolioValue: portfolioValue,
    });
  };
  const defaultFormData = {
    exitDate: new Date(),
    quantity: "",
    exitPrice: "",
    // charges: "",
    portfolioValue: "",
    entryPrice: "",
    entryQuantity: "",
    entryDate: "",
  };

  const resetFormData = () => {
    setFormData(defaultFormData);
  };
  const handleSubmit = () => {
    console.log("rowData", formData);
    const apiPayload = { ...API_CONFIG.exit_position };
    apiPayload.data = formData;
    apiClient(apiPayload)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
    setExitTradeOpen(false);
    resetFormData();
  };

  return (
    <div>
      <Modal
        open={exitTradeOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            style={{
              left: "96%",
              padding: " 0% 2% 1% 0%",
              color: "#000000",
            }}
            onClick={() => {
              setExitTradeOpen(false);
            }}
          >
            <ClearIcon />
          </IconButton>
          <div className="exit-modal">
            {/* <Typography>Date</Typography> */}
            <div className="date-component">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  placeholder="Date"
                  className="date"
                  // value={formData.date}
                  onChange={(exitDate) => {
                    const formatteddate = exitDate.format("YYYY-MM-DD");

                    handleInputChange("exitDate", formatteddate);
                  }}
                  // renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="date-and-switch">
              <div className="row-div">
                <Typography>Quantity</Typography>
                <Input
                  type="number"
                  className="text-field"
                  variant="outlined"
                  value={formData.quantity}
                  onChange={(e) =>
                    handleInputChange("quantity", parseFloat(e.target.value))
                  }
                />
              </div>
            </div>
            {/* <div>
              <Typography>Quantity</Typography>
              <Input
                className="text-field"
                variant="outlined"
                // placeholder="Quantity"

                value={formData.quantity}
                onChange={(e) =>
                  handleInputChange("quantity", parseInt(e.target.value))
                }
              />
            </div> */}
            <div>
              <Typography>Exit Price</Typography>
              <Input
                className="text-field"
                // placeholder="Exit Price"
                variant="outlined"
                value={formData.exitPrice}
                onChange={(e) =>
                  handleInputChange("exitPrice", parseFloat(e.target.value))
                }
              />
            </div>
            {/* <div>
              <Typography>Charges</Typography>
              <Input
                className="text-field"
                // placeholder="Charges"
                variant="outlined"
                value={formData.charges}
                onChange={(e) =>
                  handleInputChange("charges", parseFloat(e.target.value))
                }
              />
            </div> */}
            <Button
              variant="contained"
              className="exit-btn"
              style={{ width: "15%" }}
              onClick={handleSubmit}
            >
              {" "}
              Exit Trade
            </Button>
          </div>
        </Box>
      </Modal>{" "}
    </div>
  );
};

export default ExitTradeModal;
