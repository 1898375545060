import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@mui/material";
const SquaredOffPositionTable = ({ exitTableData }) => {
  console.log(exitTableData);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.purple,
      backgroundColor: "rgba(0, 46, 100, 1)",
      color: theme.palette.common.white,
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: "bold",
      position: "relative",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const COL_LIST = [
    { key: "Exit Date", value: "exitDate" },
    { key: "Company Name", value: "companyName" },
    { key: "Company Ticker", value: "ticker" },
    { key: "Trade Type", value: "tradeType" },
    { key: "Exit Price", value: "exitPrice" },
    { key: "Quantity", value: "quantity" },

    { key: "Realised Return [%]", value: "net_realised_pnl" },
  ];

  const renderHeadCol = () => {
    return COL_LIST.map((row, index) => {
      return (
        <StyledTableCell key={`head_${row.label}_${index}`} align="center">
          {row?.key}
        </StyledTableCell>
      );
    });
  };
  const renderRows = () => {
    if (!exitTableData || exitTableData.length === 0) {
      // Render a message when there's no data
      return (
        <StyledTableRow>
          <StyledTableCell colSpan={COL_LIST.length} align="center">
            No data available
          </StyledTableCell>
        </StyledTableRow>
      );
    }

    return exitTableData.map((data, dataIndex) => (
      <StyledTableRow key={`row_${dataIndex}`}>
        {COL_LIST.map((col, colIndex) => (
          <StyledTableCell key={`cell_${dataIndex}_${colIndex}`} align="center">
            {typeof data[col.value] === "number"
              ? data[col.value].toFixed(2)
              : data[col.value]}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    ));
  };
  return (
    <div>
      {" "}
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <TableRow>{renderHeadCol()}</TableRow>
          </TableHead>
          <TableBody>{renderRows()}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SquaredOffPositionTable;
