import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import ExitTradeModal from "./ExitTradeModal";
const CustomizedTables = ({ tableData, portfolioValue }) => {
  const [rowdata, SetRowData] = React.useState("");

  // const [tableData, setTableData] = React.useState();
  // const dispatch = useDispatch();
  // let API_CONFIG = {
  //   fetchPortfolio: {
  //     method: "GET",
  //     url: "/fetchActivePosition",
  //     data: {},
  //   },
  // };
  // const fetchActivePosition = () => {
  //   const apiPayload = { ...API_CONFIG.fetchPortfolio };
  //   apiPayload.url += `?name=${portfolioValue}`;
  //   apiClient(apiPayload)
  //     .then((res) => {
  //       console.log(res?.data);
  //       setTableData(res?.data);
  //     })
  //     .catch((err) => {
  //       console.log({ message: err });
  //       dispatch(apiFailure(err));
  //     });
  // };
  // React.useEffect(() => {
  //   fetchActivePosition();
  // }, [portfolioValue]);

  const [exitTradeOpen, setExitTradeOpen] = React.useState(false);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.purple,
      backgroundColor: "rgba(0, 46, 100, 1)",
      color: theme.palette.common.white,
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: "bold",
      position: "relative",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const COL_LIST = [
    { key: "Entry Date", value: "date" },

    { key: "Company Name", value: "companyName" },
    { key: "Company Ticker", value: "ticker" },
    { key: "Trade Type", value: "buySell" },
    { key: "Latest Traded Price", value: "cmp" },

    { key: "Daily Change [%]", value: "dailyChange" },

    { key: "Entry Price", value: "entryPrice" },
    { key: "Quantity", value: "quantity" },

    { key: "Holding Period Return [%]", value: "allTimeChange" },

    // { key: "Current Market Price", value: "cmp" },
    // { key: "Profit/Loss", value: "pnl" },
    // { key: "Stoploss Price", value: "stopLoss" },
    // { key: "Target Price", value: "targetPrice" },
    // { key: "Amount Invested", value: "Total_amount_invested" },
    // { key: "Current Value", value: "currentValue" },
    // {
    //   key: "Weightage of Investment [%]",
    //   value: "weightageOfInvestmentPercent",
    // },
    { key: "Exit Trade", value: "Exit Trade" },
  ];

  const renderHeadCol = () => {
    return COL_LIST.map((row, index) => {
      return (
        <StyledTableCell key={`head_${row.label}_${index}`} align="center">
          {row?.key}
        </StyledTableCell>
      );
    });
  };
  const renderRows = () => {
    if (!tableData || tableData.length === 0) {
      // Render a message when there's no data
      return (
        <StyledTableRow>
          <StyledTableCell colSpan={COL_LIST.length} align="center">
            No data available
          </StyledTableCell>
        </StyledTableRow>
      );
    }

    return tableData.map((data, dataIndex) => (
      <StyledTableRow key={`row_${dataIndex}`}>
        {COL_LIST.map((col, colIndex) => (
          <StyledTableCell key={`cell_${dataIndex}_${colIndex}`} align="center">
            {col.value === "Exit Trade" ? (
              <Button
                onClick={() => {
                  setExitTradeOpen(true);
                  SetRowData(data);
                }}
              >
                Exit Trade
              </Button>
            ) : typeof data[col.value] === "number" ? (
              col.value === "allTimeChange" ? (
                (data[col.value] * 100).toFixed(2)
              ) : (
                data[col.value].toFixed(2)
              )
            ) : (
              data[col.value]
            )}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    ));
  };

  return (
    <>
      <ExitTradeModal
        exitTradeOpen={exitTradeOpen}
        setExitTradeOpen={setExitTradeOpen}
        rowdata={rowdata}
        portfolioValue={portfolioValue}
      ></ExitTradeModal>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>{renderHeadCol()}</TableRow>
          </TableHead>
          <TableBody>{renderRows()}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomizedTables;
