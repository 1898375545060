import React, { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import { useHistory } from "react-router-dom";
import { CustomHeader } from "./CustomHeader";
import apiClient from "../../utils/apiClient";
import { apiFailure } from "../../logic";
import { useDispatch } from "react-redux";
import "./index.css";

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import "./index.css";

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);
const POSStatistics = () => {
  let API_CONFIG = {
    posData: {
      method: "GET",
      url: "/getPosStatistics/",
    },
    predictionData: {
      method: "GET",
      url: "/getPredictionStatistics/",
    },
  };
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);

  const gridRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const gridStyle = useMemo(
    () => ({ width: "100%", height: "77%", marginTop: "4%", padding: "0 2%" }),
    []
  );

  const [posStatistics, setPosStatistics] = useState();
  const [predictionStatistics, setPredictionStatistics] = useState();

  const currencyFormatter = (params) => {
    return formatNumber(params.value);
  };

  const formatNumber = (number) => {
    return Number(number).toFixed(2).toString();
  };

  //   const usFormatter = (params) => {
  //     return Intl.NumberFormat("en-US").format(Number(params.value));
  //   };

  const handleCompanyNameClick = (name) => {
    history.push(`/company_info/?symbol=${name}`);
    //window.colState = gridRef.current.columnApi.getColumnState();
    // console.log("Saved State",gridRef.current.columnApi.getColumnState());
  };

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    };
  });
  //   "ticker": "IKNA",
  //   "clinical_id": "NCT05228015",
  //   "final_date_of_pos": "27/09/2023",
  //   "pass_probability": 50.6,
  //   "prediction": "Pass",
  //   "actual_result": "Pass",
  //   "comparison": "TRUE",
  //   "type": "Interim",
  //   "readout_result": "Phase 1 data resulted in minimal treatment-related proteinuria without any dose reductions or treatment interruptions, noted November 9, 2023. Additional IK-930 clinical data update planned for the 2H 2024.",
  //   "link": "https://ir.ikenaoncology.com/news-releases/news-release-details/ikena-oncology-shares-initial-positive-and-differentiated-dose",
  //   "drug": "IK-930",
  //   "phase": "Phase 1",
  //   "position_taken": "",
  //   "entry_date": "",
  //   "entry_price": "",
  //   "ltp": "",
  //   "return_percent": null
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Ticker",
      field: "ticker",
      filter: "agTextColumnFilter",
      pinned: "left",
      filterParams: {
        filterOptions: [
          "contains",
          "Not contains",
          "Equals",
          "Not equal",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      cellRendererFramework: function (params) {
        return (
          <a onClick={() => handleCompanyNameClick(params.value)}>
            {params.value}
          </a>
        );
      },
      cellClass: "tickerCell",
      maxWidth: 100,
    },
    {
      headerName: "NCT ID",
      field: "clinical_id",

      filter: "agTextColumnFilter",
      pinned: "left",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      headerClass: "companyHeader",
      //   cellClass: "companyCell",
    },
    {
      headerName: "Drug",
      field: "drug",
      tooltipField: "drug",

      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 180,
    },
    {
      headerName: "Phase",
      field: "phase",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 130,
    },
    {
      headerName: "Indication",
      field: "indication",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 150,
      tooltipField: "indication",
    },
    {
      headerName: "Date of POS",
      field: "final_date_of_pos",
      filter: "agTextColumnFilter",

      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 200,
    },
    {
      headerName: "Pass Probability",
      field: "pass_probability",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      valueFormatter: currencyFormatter,
      minWidth: 160,
    },
    {
      headerName: "Prediction",
      field: "prediction",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 140,
    },
    {
      headerName: "Actual Result",
      field: "actual_result",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 160,
    },
    {
      headerName: "Prediction Accuracy",
      field: "comparison",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 180,
    },
    {
      headerName: "Event Type",
      field: "type",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 150,
    },
    {
      headerName: "Readout Result",
      field: "readout_result",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 300,
      tooltipField: "readout_result",
    },
    //  {
    //   headerName:"PEG Ratio",
    //   field:"PEGRatio",
    //   filter:"agNumberColumnFilter",
    //   valueFormatter:currencyFormatter,
    //     filterParams:{
    //     filterOptions:["inRange","lessThan","lessThanOrEqual","greaterThan","greaterThanOrEqual"],
    //     buttons:["clear","reset","apply"],
    //     closeOnApply:true,
    //     },
    //    minWidth:125,
    // },
    {
      headerName: "Readout Link",
      field: "link",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 300,
      tooltipField: "link",
    },

    {
      headerName: "Position Taken",
      field: "position_taken",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 180,
    },
    //  {
    //   headerName:"Quick Ratio",
    //   field:"Quick_Ratio",
    //   filter:"agNumberColumnFilter",
    //   valueFormatter:currencyFormatter,
    //     filterParams:{
    //     filterOptions:["inRange","lessThan","lessThanOrEqual","greaterThan","greaterThanOrEqual"],
    //     buttons:["clear","reset","apply"],
    //     closeOnApply:true,
    //     },
    //   minWidth:140,

    // },
    {
      headerName: "Entry Date",
      field: "entry_date",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 160,
    },
    //  {
    //   headerName:"RoA",
    //   field:"RoA",
    //   filter:"agNumberColumnFilter",
    //      valueFormatter:currencyFormatter,
    //        filterParams:{
    //     filterOptions:["inRange","lessThan","lessThanOrEqual","greaterThan","greaterThanOrEqual"],
    //     buttons:["clear","reset","apply"],
    //     closeOnApply:true,
    //     },
    //    minWidth:100,
    // },
    //  {
    //   headerName:"RoE",
    //   field:"RoE",
    //   filter:"agNumberColumnFilter",
    //   valueFormatter:currencyFormatter,
    //     filterParams:{
    //     filterOptions:["inRange","lessThan","lessThanOrEqual","greaterThan","greaterThanOrEqual"],
    //     buttons:["clear","reset","apply"],
    //     closeOnApply:true,
    //     },
    //   minWidth:100,
    // },
    {
      headerName: "Entry Price",
      field: "entry_price",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 225,
    },
    {
      headerName: "CMP",
      field: "ltp",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 175,
    },
    {
      headerName: "Return [%]",
      field: "return_percent",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      //   filter: "agNumberColumnFilter",
      //   filterParams: {
      //     filterOptions: [
      //       "inRange",
      //       "lessThan",
      //       "lessThanOrEqual",
      //       "greaterThan",
      //       "greaterThanOrEqual",
      //     ],
      //     buttons: ["clear", "reset", "apply"],
      //     closeOnApply: true,
      //   },
      //   valueFormatter: currencyFormatter,
      minWidth: 175,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      sortable: true,
      suppressMovable: true,
      cellClass: ["suppress-movable-col"],
      resizable: true,
      headerComponentParams: {
        menuIcon: "fa-bars",
      },
      cellStyle: { fontSize: "0.8rem" },
    };
  }, []);

  const getCompanyScreenerData = () => {
    const apiPayload = { ...API_CONFIG.posData };
    apiClient(apiPayload)
      .then((res) => {
        console.log(res);
        // setCompanyScreenerRowData({ data: res });
        setPosStatistics(res?.data);
      })
      .catch((err) => {
        console.log({ message: err });
        setColumnDefs([...columnDefs]);
        dispatch(apiFailure(err));
      });
  };
  const getPredictionData = () => {
    const apiPayload = { ...API_CONFIG.predictionData };
    apiClient(apiPayload)
      .then((res) => {
        console.log("prediction", res);
        setPredictionStatistics(res?.data);
      })
      .catch((err) => {
        console.log({ message: err });
        setColumnDefs([...columnDefs]);
        dispatch(apiFailure(err));
      });
  };

  const onGridReady = useCallback((params) => {
    //    fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
    //    .then((res)=>res.json())
    //    .then((data)=>{
    //        console.log("From Screners page",data,rowData);
    //       setRowData(data);

    //    }).catch((err)=>{
    //        console.log(err);
    //        setColumnDefs([...columnDefs]);
    //    });

    getCompanyScreenerData();
    getPredictionData();
  });
  const gridOptions = {
    autoSizeColumns: true,
    enableSorting: true,
    enableFilter: true,
    suppressHorizontalScroll: false,
    // getCellTooltip: (params) => params.value,
    tooltipInteraction: true,
  };

  return (
    <>
      {/* margin-top: 0;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500; */}
      <h2
        style={{
          fontWeight: "bolder",
          textAlign: "left",
          padding: "1% 0px 0px 2%",
        }}
      >
        PoS STATISTICS
      </h2>
      <div
        className="pos-statistics"
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <table
          border="2"
          style={{ backgroundColor: "white", borderCollapse: "collapse" }}
          className="pos-table"
          title="Accuracy = (Total Correct Predictions / Total Prediction)"
        >
          <thead>
            <tr>
              <th className="pos-table-header" colSpan={2}>
                Total Prediction
              </th>{" "}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="pos-table-cell">
                Count :&nbsp;{predictionStatistics?.total_prediction?.count}
              </td>
              <td className="pos-table-cell">
                Accuracy :&nbsp;
                {predictionStatistics?.total_prediction?.accuracy.toFixed(2)}
                &nbsp;%
              </td>
            </tr>
          </tbody>
        </table>{" "}
        <table
          border="2"
          style={{ backgroundColor: "white", borderCollapse: "collapse" }}
          className="pos-table"
          title="Accuracy = (Total Correct Pass Predictions / Total Pass Prediction)"
        >
          <thead>
            <tr>
              <th className="pos-table-header" colSpan={2}>
                Pass Prediction
              </th>{" "}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="pos-table-cell">
                Count :&nbsp;{predictionStatistics?.pass_prediction?.count}
              </td>
              <td className="pos-table-cell">
                Accuracy :&nbsp;
                {predictionStatistics?.pass_prediction?.accuracy.toFixed(2)}
                &nbsp;%
              </td>
            </tr>
          </tbody>
        </table>{" "}
        <table
          border="2"
          style={{ backgroundColor: "white", borderCollapse: "collapse" }}
          className="pos-table"
          title="Accuracy = (Total Correct Fail Predictions / Total Fail Prediction)"
        >
          <thead>
            <tr>
              <th className="pos-table-header" colSpan={2}>
                Fail Prediction
              </th>{" "}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="pos-table-cell">
                Count :&nbsp;{predictionStatistics?.fail_prediction?.count}
              </td>
              <td className="pos-table-cell">
                Accuracy :&nbsp;
                {predictionStatistics?.fail_prediction?.accuracy.toFixed(2)}
                &nbsp;%
              </td>
            </tr>
          </tbody>
        </table>{" "}
      </div>
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            //   suppressHorizontalScroll={false}
            ref={gridRef}
            rowData={posStatistics}
            columnDefs={columnDefs}
            supressMenuHide={true}
            components={components}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={15}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
          ></AgGridReact>
        </div>
      </div>
    </>
  );
};

export default POSStatistics;
